// @import url('https://use.typekit.net/awc4crb.css');

@font-face {
  font-family: 'Adelle Sans';
  font-display: swap;
  src: url('https://cdn.hiredigital.com/fonts/AdelleSans_Set-web/AdelleSans-Regular.woff2')
      format('woff2'),
    url('https://cdn.hiredigital.com/fonts/AdelleSans_Set-web/AdelleSans-Regular.woff')
      format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  font-display: swap;
  src: url('https://cdn.hiredigital.com/fonts/AdelleSans_Set-web/AdelleSans-Italic.woff2')
      format('woff2'),
    url('https://cdn.hiredigital.com/fonts/AdelleSans_Set-web/AdelleSans-Italic.woff')
      format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  font-display: swap;
  src: url('https://cdn.hiredigital.com/fonts/AdelleSans_Set-web/AdelleSans-Semibold.woff2')
      format('woff2'),
    url('https://cdn.hiredigital.com/fonts/AdelleSans_Set-web/AdelleSans-Semibold.woff')
      format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  font-display: swap;
  src: url('https://cdn.hiredigital.com/fonts/AdelleSans_Set-web/AdelleSans-Bold.woff2')
      format('woff2'),
    url('https://cdn.hiredigital.com/fonts/AdelleSans_Set-web/AdelleSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

// @font-face {
//   font-family: 'Cerebri Sans';
//   font-display: swap;
//   src: url('https://cdn.hiredigital.com/fonts/cerebri-sans-regular/cerebri-sans-regular.eot');
//   src: url('https://cdn.hiredigital.com/fonts/cerebri-sans-regular/cerebri-sans-regular.eot?#iefix')
//       format('embedded-opentype'),
//     url('https://cdn.hiredigital.com/fonts/cerebri-sans-regular/cerebri-sans-regular.woff2') format('woff2'),
//     url('https://cdn.hiredigital.com/fonts/cerebri-sans-regular/cerebri-sans-regular.woff') format('woff'),
//     url('https://cdn.hiredigital.com/fonts/cerebri-sans-regular/cerebri-sans-regular.ttf') format('truetype'),
//     url('https://cdn.hiredigital.com/fonts/cerebri-sans-regular/cerebri-sans-regular.svg#youworkforthem')
//       format('svg');
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Cerebri Sans';
//   font-display: swap;
//   src: url('https://cdn.hiredigital.com/fonts/cerebri-sans-medium/cerebri-sans-medium.eot');
//   src: url('https://cdn.hiredigital.com/fonts/cerebri-sans-medium/cerebri-sans-medium.eot?#iefix')
//       format('embedded-opentype'),
//     url('https://cdn.hiredigital.com/fonts/cerebri-sans-medium/cerebri-sans-medium.woff2') format('woff2'),
//     url('https://cdn.hiredigital.com/fonts/cerebri-sans-medium/cerebri-sans-medium.woff') format('woff'),
//     url('https://cdn.hiredigital.com/fonts/cerebri-sans-medium/cerebri-sans-medium.ttf') format('truetype'),
//     url('https://cdn.hiredigital.com/fonts/cerebri-sans-medium/cerebri-sans-medium.svg#youworkforthem')
//       format('svg');
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Cerebri Sans';
//   font-display: swap;
//   src: url('https://cdn.hiredigital.com/fonts/cerebri-sans-semibold/cerebri-sans-semibold.eot');
//   src: url('https://cdn.hiredigital.com/fonts/cerebri-sans-semibold/cerebri-sans-semibold.eot?#iefix')
//       format('embedded-opentype'),
//     url('https://cdn.hiredigital.com/fonts/cerebri-sans-semibold/cerebri-sans-semibold.woff2') format('woff2'),
//     url('https://cdn.hiredigital.com/fonts/cerebri-sans-semibold/cerebri-sans-semibold.woff') format('woff'),
//     url('https://cdn.hiredigital.com/fonts/cerebri-sans-semibold/cerebri-sans-semibold.ttf') format('truetype'),
//     url('https://cdn.hiredigital.com/fonts/cerebri-sans-semibold/cerebri-sans-semibold.svg#youworkforthem')
//       format('svg');
//   font-weight: 600;
//   font-style: normal;
// }
